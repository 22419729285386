var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "slideX", mode: "out-in" } },
        [
          !_vm.showOtp
            ? _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "\n          md:text-2xl\n          text-xl text-center\n          font-bold\n          primary-text\n          leading-10\n        ",
                    },
                    [_vm._v(" Phone number ")]
                  ),
                  _c("p", { staticClass: "text-center text-grey md:text-lg" }, [
                    _vm._v(" Please provide an active phone number "),
                  ]),
                  _c("Input", {
                    staticClass: "mt-8",
                    attrs: {
                      type: "tel",
                      placeholder: "Enter your phone number",
                      width: "w-full",
                      validation: _vm.rules.phone,
                      errorText: "Enter a valid phone number",
                      maxlength: _vm.user.phone.includes("+") ? "14" : "11",
                      number: "",
                    },
                    model: {
                      value: _vm.user.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "phone", $$v)
                      },
                      expression: "user.phone",
                    },
                  }),
                  _c("Button", {
                    staticClass: "mt-8",
                    attrs: {
                      text: "Continue",
                      width: "w-full",
                      type: "submit",
                      loading: _vm.otpLoading,
                      disabled: _vm.disableButton,
                    },
                    on: { click: _vm.sendOtp },
                  }),
                ],
                1
              )
            : _c("Otp", {
                attrs: { phoneNumber: _vm.user.phone },
                on: { next: _vm.handleNextStep },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }