<template>
  <div>
    <transition name="slideX" mode="out-in">
      <div v-if="!showOtp">
        <h5
          class="
            md:text-2xl
            text-xl text-center
            font-bold
            primary-text
            leading-10
          "
        >
          Phone number
        </h5>
        <p class="text-center text-grey md:text-lg">
          Please provide an active phone number
        </p>
        <!-- <form @submit.prevent="sendOtp"> -->
        <Input
          type="tel"
          class="mt-8"
          placeholder="Enter your phone number"
          width="w-full"
          v-model="user.phone"
          :validation="rules.phone"
          errorText="Enter a valid phone number"
          :maxlength="user.phone.includes('+') ? '14' : '11'"
          number
        />
        <Button
          @click="sendOtp"
          text="Continue"
          class="mt-8"
          width="w-full"
          type="submit"
          :loading="otpLoading"
          :disabled="disableButton"
        />
        <!-- <p
          class="
            text-font-semibold text-brandPurple text-center
            cursor-pointer
            mt-8
          "
          @click="skipPhone"
        >
          Skip, I will do it later
        </p> -->
        <!-- </form> -->
      </div>
      <Otp :phoneNumber="user.phone" v-else @next="handleNextStep" />
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { KYC_STEPS } from "../../data";

export default {
  components: {
    Input,
    Button,
    Otp: () => import("./Otp.vue"),
  },
  data: () => ({
    showOtp: false,
    otpLoading: false,
    user: {
      phone: "",
      otp: "",
    },
  }),
  computed: {
    ...mapState({
      userData: (state) => state?.auth?.user,
    }),
    rules() {
      return {
        phone:
          /^[+]?\d+$/.test(this.user.phone) && this.user.phone.includes("+")
            ? this.user.phone.length === 14
            : this.user.phone.length === 11,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  methods: {
    ...mapActions("userInfo", ["initiatePhoneVerification"]),
    ...mapActions("notification", ["showToast"]),
    ...mapActions("dashboard", ["handleKycStep"]),

    sendOtp() {
      this.otpLoading = true;
      this.initiatePhoneVerification(this.user.phone)
        .then(() => {
          this.showToast({
            display: true,
            icon: "success",
            description: "OTP has been sent to your phone number",
          });
          this.otpLoading = false;
          this.showOtp = true;
        })
        .catch(() => {
          this.otpLoading = false;
        });
    },
    skipPhone() {
      this.handleNextStep();
    },
    handleNextStep() {
      // if (!this.userData?.selfieVerified) {
      //   this.handleKycStep(KYC_STEPS.camera);
      // } else {
      this.handleKycStep(KYC_STEPS.address);
      // }
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
</style>
